/* eslint-disable @typescript-eslint/no-redeclare */
import {
  Analytics as AmplifyAnalytics,
  AnalyticsProvider,
} from '@aws-amplify/analytics';
import { EventAttributes } from '@aws-amplify/analytics/lib-esm/types';
import CustomizedPinpointProvider from '@clarity-website/common/CustomizedPinpointProvider';
import analyticsStorage from '@clarity-website/common/analyticsStorage';
import { sendImperativeEvent } from '@clarity-website/common/telemetry/TelemetryUtils';
import {
  User,
  currentUserStateAtom,
} from '@clarity-website/common/userProviderAtoms';
import { AppConfig } from '@clarity-website/config/stage-config';
import useAppConfig from '@clarity-website/config/useAppConfig';
import useWindowSize, {
  readOnlyWindowSizeAtom,
} from '@clarity-website/utils/UseWindowSize';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const APP_NAME = 'PiClarity';

// Parses the base path of a URI. See below for examples:
// returns "/your-reports" for "/your-reports/90494662-4282-402a-aaa8-b13f22f352e1"
// "/" for "/"
// "/browse" for "/browse"
export function parseBasePath(
  pathname: string = window.location.pathname,
): string {
  if (!pathname.startsWith('/')) {
    return '';
  }

  return `/${pathname.split('/')[1]}`;
}

export const analyticsURIBasePath = atom<string>(parseBasePath());
export const analyticsDomainAtom = atom<string>('none');
export const analyticsAttributesAtom = atom<EventAttributes>(
  (get): EventAttributes => {
    const { user } = get(currentUserStateAtom);
    const windowSize = get(readOnlyWindowSizeAtom);
    const uriBasePath = get(analyticsURIBasePath);
    const domain = get(analyticsDomainAtom);

    const {
      innerWidth,
      innerHeight,
      outerWidth,
      outerHeight,
      screen: { width, height },
    } = windowSize;

    return {
      appName: APP_NAME,
      appVersion: APP_VERSION || '',
      employeeLogin: user?.login || '',
      employeeFirstName: user?.firstName || '',
      employeeLastName: user?.lastName || '',
      employeeCountry: user?.country || '',
      employeeSite: user?.site || '',
      employeeGroups: JSON.stringify(user?.groups || []),
      employeeAdGroups: user?.adGroups || '',
      employeePosixGroups: user?.posixGroups || '',
      employeeJobTitle: user?.jobTitle || '',
      employeeJobLevel: user?.jobLevel || '',
      domain,
      uriBasePath,
      // Amplify does not support objects as default attributes, JSON strings will be parsed by Elasticsearch processor
      screen: JSON.stringify({ width, height }),
      viewport: JSON.stringify({
        innerWidth,
        innerHeight,
        outerWidth,
        outerHeight,
      }),
    };
  },
);

const APP_VERSION = process.env.REACT_APP_VERSION;
export const AnalyticsActionTypes = Object.freeze({
  CLICK: 'click',
  KEYSDOWN: 'keysDown',
  HOVER: 'hover',
  PAGE_LOAD: 'page-load',
  IN_VIEW: 'in-view',
  DRAG: 'drag',
});
export type AnalyticsActionTypes =
  (typeof AnalyticsActionTypes)[keyof typeof AnalyticsActionTypes];

export const AnalyticsActionCategories = Object.freeze({
  NAVBAR: 'navBar',
  SEARCH: 'search',
  WIDGET: 'widget',
  BROWSE: 'browse',
  REPORT: 'report',
  TABLE: 'table',
  TAB: 'tab',
  ONBOARDING: 'onboarding',
  FAQ: 'faq',
  SUPPORT: 'support',
  EDIT: 'edit',
  MODIFY_LAYOUT: 'modifyLayout',
  FULL_SCREEN: 'fullScreen',
  HOMEPAGE: 'homepage',
  TABLE_DROPDOWN: 'tableDropdown',
  CLARITY_ASSIST: 'clarityAssist',
  GLOBAL: 'global',
  EDIT_GOALS: 'editGoals',
  OFFLINE_DOWNLOADS: 'offlineDownloads',
});
export type AnalyticsActionCategories =
  (typeof AnalyticsActionCategories)[keyof typeof AnalyticsActionCategories];

interface AnalyticsInterface {
  children: React.ReactElement;
}

/*
  webVitalsMetrics is an array of the 5 web vitals  metrics provided by web-vitals lib (https://www.npmjs.com/package/web-vitals),
  and reported in reportWebVitals.ts

  FCP: First Contentful Paint
  CLS: Cumulative Layout Shift
  LCP: Largest Contentful Paint
  TTFB: Time to First Byte
  FID: First Input Delay
 */
const webVitalsMetrics: Array<string> = ['FCP', 'CLS', 'LCP', 'TTFB', 'FID'];

// todo: we could add this as a hook and have it synced directly with the global state.
// This would allow us to remove the singleton object. However, to avoid code duplication
// we should also ensure to make record part of a React hook and we're currently blocked with this as
// record is called from outside the React tree.
const enableAutoTrack = (
  attributes: () => EventAttributes,
  provider: AnalyticsProvider,
) => {
  AmplifyAnalytics.autoTrack('session', {
    // REQUIRED, turn on/off the auto tracking
    enable: true,
    // OPTIONAL, the attributes of the event, you can either pass an object or a function
    // which allows you to define dynamic attributes
    attributes,
    // when using function
    // attributes: () => {
    //    const attr = somewhere();
    //    return {
    //        myAttr: attr
    //    }
    // },
    // OPTIONAL, the service provider, by default is the AWS Pinpoint
    provider: provider.getProviderName(),
  });

  AmplifyAnalytics.autoTrack('pageView', {
    // REQUIRED, turn on/off the auto tracking
    enable: true,
    // OPTIONAL, the event name, by default is 'pageView'
    // eventName: 'pageView',
    // OPTIONAL, the attributes of the event, you can either pass an object or a function
    // which allows you to define dynamic attributes
    attributes,
    // attributes: {
    // attr: 'attr'
    // },
    // when using function
    // attributes: () => {
    //    const attr = somewhere();
    //    return {
    //        myAttr: attr
    //    }
    // },
    // OPTIONAL, by default is 'multiPageApp'
    // you need to change it to 'SPA' if your app is a single-page app like React
    type: 'SPA',
    // OPTIONAL, the service provider, by default is the AWS Pinpoint
    provider: provider.getProviderName(),
    // OPTIONAL, to get the current page url
    getUrl: () => window.location.href,
  });

  AmplifyAnalytics.autoTrack('event', {
    // REQUIRED, turn on/off the auto tracking
    enable: true,
    // OPTIONAL, events you want to track, by default is 'click'
    events: [AnalyticsActionTypes.CLICK, AnalyticsActionTypes.KEYSDOWN],
    // OPTIONAL, the prefix of the selectors, by default is 'data-amplify-analytics-'
    // in order to avoid collision with the user agent, according to https://www.w3schools.com/tags/att_global_data.asp
    // always put 'data' as the first prefix
    selectorPrefix: 'data-amplify-analytics-',
    // OPTIONAL, the service provider, by default is the AWS Pinpoint
    provider: provider.getProviderName(),
    // OPTIONAL, the default attributes of the event, you can either pass an object or a function
    // which allows you to define dynamic attributes
    attributes,
    // when using function
    // attributes: () => {
    //    const attr = somewhere();
    //    return {
    //        myAttr: attr
    //    }
    // }
  });
};

function shouldEnableAnalytics(
  config: AppConfig | undefined,
  user: User | undefined,
): boolean {
  if (!config || !config.Analytics || process.env.NODE_ENV !== 'production') {
    return false;
  }

  return shouldEnableAnalyticsForUser(
    user,
    config.Analytics.countriesToDisable,
  );
}

function shouldEnableAnalyticsForUser(
  user: User | undefined,
  disabledCountries: string[],
): boolean {
  return user !== undefined && !disabledCountries.includes(user.country);
}

export function initAnalytics(
  analyticsProvider: AnalyticsProvider,
  config: AppConfig,
) {
  AmplifyAnalytics.enable();
  AmplifyAnalytics.addPluggable(analyticsProvider);
  AmplifyAnalytics.configure(config);
  AmplifyAnalytics.updateEndpoint({}, analyticsProvider.getProviderName());
  enableAutoTrack(
    () => analyticsStorage.getGlobalAttributes(),
    analyticsProvider,
  );
}

export default function Analytics({ children }: AnalyticsInterface) {
  const { data: config } = useAppConfig();
  const { user } = useAtomValue(currentUserStateAtom);
  const setURIBasePath = useSetAtom(analyticsURIBasePath);
  const [enabled, setEnabled] = useState(false);
  const [isUserInfoInStorage, setIsUserInfoInStorages] = useState(false);
  const attributes = useAtomValue(analyticsAttributesAtom);

  // Sync attributes from global state to singleton storage
  useEffect(() => {
    const currentAttributes = analyticsStorage.getGlobalAttributes();
    analyticsStorage.setGlobalAttributes(attributes);

    // This check is to avoid a race condition between analytics storage and analytics init.
    // Basically, sometimes init is faster and triggers 'pageView' events before the user
    // info data is available which is causing issues during analytics.
    if (
      currentAttributes.employeeLogin?.length === 0 &&
      attributes.employeeLogin?.length > 0
    ) {
      setIsUserInfoInStorages(true);
    }
  }, [attributes]);

  // Track the base path of the URI
  const { pathname } = useLocation();
  useEffect(
    () => setURIBasePath(parseBasePath(pathname)),
    [pathname, setURIBasePath],
  );

  // Subscribe to "resize" event
  useWindowSize();

  // Bootstrap analytics
  useEffect(() => {
    if (!shouldEnableAnalytics(config, user)) {
      AmplifyAnalytics.disable();
      setEnabled(false);
      return;
    }

    // Ensure that we only initialize once
    if (!enabled && isUserInfoInStorage) {
      // We already check that the config is set in `shouldEnableAnalytics`, the type case is to avoid typescript errors
      initAnalytics(provider, config?.Analytics as unknown as AppConfig);
      setEnabled(true);
    }
  }, [config, enabled, isUserInfoInStorage, user]);

  return children;
}

/**
 * todo: move all calls to record to the React tree and use `record` from useAnalytics()
 * @deprecated please use `record` from useAnalytics() hook when possible as it also includes scoped attributes
 **/
export const record = (event: any) => {
  AmplifyAnalytics.record(
    buildAnalyticEventWithMetrics(
      event,
      analyticsStorage.getGlobalAttributes(),
    ),
    provider.getProviderName(),
  );
  if (!webVitalsMetrics.includes(event.name))
    sendImperativeEvent(
      buildAnalyticEventWithMetrics(
        event,
        analyticsStorage.getGlobalAttributes(),
      ),
    );
};

const buildAnalyticEventWithMetrics = (event: any, defaultAttrs: any) => {
  if (typeof event === 'object') {
    if (webVitalsMetrics.includes(event.name)) {
      return buildWebVitalsAnalyticEvent(event, defaultAttrs);
    }
    return buildUserBehaviourAnalyticEvent(event, defaultAttrs);
  }

  return buildDefaultAnalyticEvent(event, defaultAttrs);
};

const buildWebVitalsAnalyticEvent = (event: any, defaultAttrs: any) => {
  return {
    ...event,
    attributes: {
      ...defaultAttrs,
      ...event.attributes,
    },
    metrics: {
      value: event.value,
      delta: event.delta,
    },
  };
};

const buildUserBehaviourAnalyticEvent = (event: any, defaultAttrs: any) => {
  return {
    ...event,
    attributes: {
      ...defaultAttrs,
      ...event.attributes,
    },
    ...(event.metrics && {
      metrics: event.metrics,
    }),
  };
};

const buildDefaultAnalyticEvent = (event: any, defaultAttrs: any) => {
  return {
    name: event,
    attributes: defaultAttrs,
  };
};

const provider = new CustomizedPinpointProvider();
